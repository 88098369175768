import { makeStyles } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import React, { FC, useEffect, useRef, useState } from 'react'

import { useAudioPlayer } from '@/hooks/useAudioPlayer'
import { convertDurationToTimeString } from '@/utils/convertDurationToTimeString'

const useStyles = makeStyles(({ palette, spacing }) => ({
  duration: {
    padding: spacing(0, 1.5),
  },
  slider: {
    color: palette.text.secondary,
  },
}))

export const SliderProgress: FC = () => {
  const classes = useStyles()
  const { audioUrl, playing } = useAudioPlayer()
  const audioRef = useRef<HTMLAudioElement>(null)
  const [progress, setProgress] = useState(0)
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    if (audioRef.current) {
      if (playing) {
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }
    }
  }, [audioRef.current, playing])

  const getRemainingDuration = () => Math.floor(duration - progress)

  const handleProgressChange = (_event: never, amount: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = amount
      setProgress(amount)
    }
  }

  const setProgressListener = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration)
      audioRef.current.currentTime = 0

      audioRef.current.addEventListener('timeupdate', () => {
        if (audioRef.current) {
          setProgress(Math.floor(audioRef.current.currentTime))
        }
      })
    }
  }

  return (
    <>
      <Slider
        classes={{
          thumb: classes.slider,
          rail: classes.slider,
          track: classes.slider,
        }}
        value={progress}
        max={duration}
        onChange={handleProgressChange}
        aria-labelledby="continuous-slider"
      />
      <div className={classes.duration}>
        {convertDurationToTimeString(getRemainingDuration())}
      </div>
      <audio
        src={audioUrl}
        ref={audioRef}
        autoPlay
        onLoadedMetadata={setProgressListener}
      />
    </>
  )
}
