import { CssBaseline, ThemeProvider } from '@material-ui/core'
import React, { ReactNode } from 'react'

import { Layout } from '@/layout'
import { themes } from '@/theme'

const wrapPageElement = ({ element }: { element: ReactNode }) => {
  return (
    <ThemeProvider theme={themes.default}>
      <CssBaseline />
      <Layout>{element}</Layout>
    </ThemeProvider>
  )
}

export default wrapPageElement
