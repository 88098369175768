import React, { createContext, FC, useState } from 'react'

import { Toast, ToastProps } from './Toast'

type ToastState = Pick<ToastProps, 'open' | 'message' | 'severity'>

type ToastShowParams = Pick<ToastProps, 'message' | 'severity'>

type ToastContextProps = {
  show: (params: ToastShowParams) => void
  hide: () => void
}

export const ToastContext = createContext<ToastContextProps>({
  show: () => null,
  hide: () => null,
})

export const ToastContextProvider: FC = ({ children }) => {
  const [state, setState] = useState<ToastState>()

  const show = (params: ToastShowParams) => {
    setState({
      open: true,
      ...params,
    })
  }

  const hide = () => {
    setState((prev) => ({
      ...prev,
      open: false,
    }))
  }

  return (
    <ToastContext.Provider value={{ show, hide }}>
      {children}
      <Toast onClose={hide} {...state} />
    </ToastContext.Provider>
  )
}
