import { MenuItem, MenuItemProps } from '@material-ui/core'
import React, { AnchorHTMLAttributes, FC } from 'react'

type MenuItemAnchorProps = MenuItemProps &
  AnchorHTMLAttributes<HTMLAnchorElement>

export const MenuItemAnchor: FC<MenuItemAnchorProps> = (props) => {
  // TODO: remove after the ff. issue is fixed in material-ui
  // 'component' prop is not recognized by TypeScript
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <MenuItem component="a" {...props} />
}
