import { List, ListItem, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, MouseEvent } from 'react'

import { ListItemAnchor } from '@/components/common/ListItemAnchor'
import { ListItemLink } from '@/components/common/ListItemLink'
import { Route } from '@/routes'

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  listItem: {
    color: palette.primary.dark,
  },
  listItemActive: {
    fontWeight: typography.fontWeightBold,
  },
  listItemNested: {
    paddingLeft: spacing(4),
  },
  clickable: {
    cursor: 'pointer',
  },
}))

type NavMenuLinkProps = {
  className?: string
  route: Route
  onClickLink?: (event: MouseEvent<HTMLAnchorElement | HTMLLIElement>) => void
}

export const NavMenuLink: FC<NavMenuLinkProps> = ({
  className,
  route,
  onClickLink,
  ...rest
}) => {
  const classes = useStyles()

  if (route.type === 'external') {
    return (
      <ListItemAnchor
        href={route.href}
        target="_blank"
        rel="noreferrer"
        className={clsx(classes.listItem, classes.clickable, className)}
        onClick={onClickLink}
        {...rest}
      >
        {route.label}
      </ListItemAnchor>
    )
  }

  return (
    <>
      {route.children ? (
        <ListItem className={clsx(classes.listItem, className)} {...rest}>
          {route.label}
        </ListItem>
      ) : (
        <ListItemLink
          to={route.to}
          className={clsx(classes.listItem, classes.clickable, className)}
          activeClassName={classes.listItemActive}
          onClick={onClickLink}
          {...rest}
        >
          {route.label}
        </ListItemLink>
      )}
      {route.children && (
        <List disablePadding>
          {route.children.map((childRoute) =>
            childRoute.type === 'internal' ? (
              <ListItemLink
                key={childRoute.to}
                to={`${route.to}/${childRoute.to}`}
                className={clsx(
                  classes.listItem,
                  classes.clickable,
                  classes.listItemNested
                )}
                activeClassName={classes.listItemActive}
                onClick={onClickLink}
              >
                {childRoute.label}
              </ListItemLink>
            ) : (
              <ListItemAnchor
                href={childRoute.href}
                target="_blank"
                rel="noreferrer"
                className={clsx(
                  classes.listItem,
                  classes.clickable,
                  classes.listItemNested
                )}
                onClick={onClickLink}
              >
                {childRoute.label}
              </ListItemAnchor>
            )
          )}
        </List>
      )}
    </>
  )
}
