import {
  AppBar,
  Box,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React, { FC } from 'react'

import { NavMenu } from '@/layout/nav/NavMenu'
import { routes } from '@/routes'

import { HeaderLink } from './HeaderLink'
import { HeaderTitle } from './HeaderTitle'

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 96,
    boxShadow: 'none',
    borderBottom: '1px solid #eee',
    padding: spacing(0, 8, 0, 5),
    backgroundColor: palette.background.paper,
    [breakpoints.down('md')]: {
      height: 72,
      padding: spacing(0, 6, 0, 3),
    },
    [breakpoints.down('sm')]: {
      height: 56,
      padding: spacing(0, 2),
    },
  },
  nav: {
    '& > *:not(:last-child)': {
      marginRight: spacing(7),
    },
    [breakpoints.down('md')]: {
      '& > *:not(:last-child)': {
        marginRight: spacing(4),
      },
    },
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

export const Header: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <AppBar color="transparent" position="sticky" className={classes.root}>
      <HeaderTitle />
      <Box
        component="nav"
        flex="1"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.nav}
      >
        {routes.map((route) => (
          <HeaderLink key={route.label} route={route} />
        ))}
      </Box>
      {isSmallScreen && <NavMenu />}
    </AppBar>
  )
}
