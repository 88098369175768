import { Snackbar, SnackbarProps } from '@material-ui/core'
import { Alert, AlertProps } from '@material-ui/lab'
import React, { FC } from 'react'

export type ToastProps = SnackbarProps &
  Pick<AlertProps, 'severity'> & {
    onClose: () => void
  }

export const Toast: FC<ToastProps> = ({
  open,
  onClose,
  severity,
  message,
  ...rest
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={onClose}
      {...rest}
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}
