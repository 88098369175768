// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-faith-statement-tsx": () => import("./../../../src/pages/about-us/faith-statement.tsx" /* webpackChunkName: "component---src-pages-about-us-faith-statement-tsx" */),
  "component---src-pages-about-us-mission-identity-tsx": () => import("./../../../src/pages/about-us/mission-identity.tsx" /* webpackChunkName: "component---src-pages-about-us-mission-identity-tsx" */),
  "component---src-pages-about-us-our-team-tsx": () => import("./../../../src/pages/about-us/our-team.tsx" /* webpackChunkName: "component---src-pages-about-us-our-team-tsx" */),
  "component---src-pages-data-protection-tsx": () => import("./../../../src/pages/data-protection.tsx" /* webpackChunkName: "component---src-pages-data-protection-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-give-tsx": () => import("./../../../src/pages/give.tsx" /* webpackChunkName: "component---src-pages-give-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-next-steps-join-team-tsx": () => import("./../../../src/pages/next-steps/join-team.tsx" /* webpackChunkName: "component---src-pages-next-steps-join-team-tsx" */),
  "component---src-pages-next-steps-life-groups-tsx": () => import("./../../../src/pages/next-steps/life-groups.tsx" /* webpackChunkName: "component---src-pages-next-steps-life-groups-tsx" */),
  "component---src-pages-resources-prayer-tsx": () => import("./../../../src/pages/resources/prayer.tsx" /* webpackChunkName: "component---src-pages-resources-prayer-tsx" */),
  "component---src-pages-resources-sermons-tsx": () => import("./../../../src/pages/resources/sermons.tsx" /* webpackChunkName: "component---src-pages-resources-sermons-tsx" */)
}

