import { ListItem, ListItemProps } from '@material-ui/core'
import React, { AnchorHTMLAttributes, FC } from 'react'

type ListItemAnchorProps = ListItemProps &
  AnchorHTMLAttributes<HTMLAnchorElement>

export const ListItemAnchor: FC<ListItemAnchorProps> = (props) => {
  // TODO: remove after the ff. issue is fixed in material-ui
  // 'component' prop is not recognized by TypeScript
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ListItem component="a" {...props} />
}
