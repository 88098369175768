import { Divider, makeStyles } from '@material-ui/core'
import { Link } from 'gatsby'
import { rgba } from 'polished'
import React, { FC, useEffect, useState } from 'react'

import FacebookIcon from '@/assets/svg/facebook.svg'
import InstagramIcon from '@/assets/svg/instagram.svg'
import YoutubeIcon from '@/assets/svg/youtube.svg'

import { Sitemap } from './Sitemap'

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: 'block',
    backgroundColor: rgba(palette.primary.main, 0.87),
    padding: spacing(9, 12),
    color: palette.primary.contrastText,
    [breakpoints.down('sm')]: {
      padding: spacing(5, 6),
    },
  },
  divider: {
    height: 3,
    margin: spacing(8, 0, 4),
    backgroundColor: palette.primary.contrastText,
    [breakpoints.down('sm')]: {
      margin: spacing(3, 0),
    },
  },
  miscLinks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: spacing(9),

    '& > a:not(:last-child)': {
      marginRight: spacing(12),
    },
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: spacing(3),

      '& > a:not(:last-child)': {
        marginBottom: spacing(1),
      },
    },
  },
  miscLink: {
    fontSize: '0.875rem',
    letterSpacing: '0.093571em',
    color: 'inherit',
    opacity: 0.8,
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
    [breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      letterSpacing: '0.041667em',
    },
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  copyright: {
    fontSize: '0.875rem',
    letterSpacing: '0.093571em',
    [breakpoints.down('sm')]: {
      fontSize: '0.625rem',
      letterSpacing: '0.05em',
    },
  },
  socialLinks: {
    fontSize: '1.625rem',
    color: 'inherit',
    lineHeight: 0,

    '& > a:not(:last-child)': {
      marginRight: spacing(8),
    },
    [breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      '& > a:not(:last-child)': {
        marginRight: spacing(5),
      },
    },
    [breakpoints.down('xs')]: {
      alignSelf: 'center',
      marginTop: spacing(4),
    },
  },
  socialLink: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'inherit',

    '& > svg': {
      height: '1em',
      width: '1em',
    },
  },
}))

const miscLinks = [
  { label: 'Impressum', to: '/impressum' },
  { label: 'Data Protection', to: '/data-protection' },
  { label: 'Contact Us', to: '/#contact-us' },
]

const socialLinks = [
  {
    label: 'Facebook',
    icon: <FacebookIcon />,
    href: 'https://www.facebook.com/IBCBerlin',
  },
  {
    label: 'YouTube',
    icon: <YoutubeIcon />,
    href: 'https://www.youtube.com/user/ibcberlin',
  },
  {
    label: 'Instagram',
    icon: <InstagramIcon />,
    href: 'https://instagram.com/ibc.berlin',
  },
]

const copyrightText = `©${new Date().getFullYear()} International Baptist Church Berlin`

export const Footer: FC = () => {
  const classes = useStyles()
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return (
    <footer className={classes.root}>
      <Sitemap />
      <Divider light className={classes.divider} />
      <div className={classes.miscLinks}>
        {miscLinks.map((link) => (
          <Link key={link.label} to={link.to} className={classes.miscLink}>
            {link.label}
          </Link>
        ))}
      </div>
      <div className={classes.bottomRow}>
        <div className={classes.copyright}>{copyrightText}</div>
        <div className={classes.socialLinks}>
          {socialLinks.map((link) => (
            <a
              key={link.label}
              href={link.href}
              target="_blank"
              rel="noreferrer"
              className={classes.socialLink}
            >
              {link.icon}
            </a>
          ))}
        </div>
      </div>
    </footer>
  )
}
