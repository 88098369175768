import { IconButton, makeStyles } from '@material-ui/core'
import { Close, Pause, PlayArrow } from '@material-ui/icons'
import { rgba } from 'polished'
import React, { FC } from 'react'

import { useAudioPlayer } from '@/hooks/useAudioPlayer'
import { SliderProgress } from '@/layout/player/SliderProgress'

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    bottom: spacing(3),
    right: spacing(3),
    borderRadius: 4,
    width: 480,
    color: palette.text.secondary,
    background: rgba(palette.secondary.main, 0.9),
    padding: spacing(1.5, 2),
    [breakpoints.down('xs')]: {
      bottom: 0,
      right: 0,
      borderRadius: 0,
      width: '100%',
      padding: spacing(1, 1.5),
    },
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: spacing(0, 1.5),
    width: 560,
  },
  buttons: {
    background: 'transparent',
    color: palette.text.secondary,
    justifyContent: 'center',
    fontSize: 0,
    border: 0,
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'filter 0.2s',
    '&:hover': {
      filter: 'brightness(0.5)',
    },
  },
}))

const ButtonPlayPause: FC = () => {
  const classes = useStyles()
  const { playing, togglePlayPause } = useAudioPlayer()

  return (
    <IconButton
      className={classes.buttons}
      size="small"
      onClick={togglePlayPause}
    >
      {playing ? <Pause /> : <PlayArrow />}
    </IconButton>
  )
}

const ButtonClose: FC = () => {
  const classes = useStyles()
  const { closePlayer } = useAudioPlayer()

  return (
    <IconButton className={classes.buttons} size="small" onClick={closePlayer}>
      <Close />
    </IconButton>
  )
}

export const AudioPlayer: FC = (props) => {
  const classes = useStyles()
  const { enabled, title } = useAudioPlayer()

  if (!enabled) {
    return null
  }

  return (
    <div className={classes.root} {...props}>
      <ButtonPlayPause />
      <div className={classes.title}>{title}</div>
      <SliderProgress />
      <ButtonClose />
    </div>
  )
}
