import { makeStyles } from '@material-ui/core'
import { Link } from 'gatsby'
import React, { FC } from 'react'

import logo from '@/assets/webp/ibcb-logo.webp'

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      color: palette.primary.dark,
      textDecoration: 'none',
    },
    logo: {
      height: 70,
      marginRight: spacing(1.5),
      [breakpoints.down('md')]: {
        height: 48,
      },
      [breakpoints.down('sm')]: {
        height: 32,
      },
    },
    shortName: {
      fontSize: '1.25rem',
      fontWeight: typography.fontWeightBold,
      lineHeight: 1.3,
      letterSpacing: '0.05em',
      [breakpoints.down('md')]: {
        fontSize: '1.125rem',
      },
      [breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    longName: {
      fontSize: '1.25rem',
      lineHeight: 1.3,
      letterSpacing: '0.05em',
      [breakpoints.down('md')]: {
        fontSize: '1.125rem',
      },
      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
)

export const HeaderTitle: FC = () => {
  const classes = useStyles()

  return (
    <Link to="/" className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />
      <div>
        <div className={classes.shortName}>IBCB</div>
        <div className={classes.longName}>
          International Baptist Church Berlin
        </div>
      </div>
    </Link>
  )
}
