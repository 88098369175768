import {
  createTheme,
  responsiveFontSizes,
  Theme,
} from '@material-ui/core/styles'
import { rgba } from 'polished'

const makeTheme = (): Theme => {
  const baseTheme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#03588c',
        contrastText: '#fefefe',
      },
      secondary: {
        main: '#f0c985',
      },
      text: {
        primary: '#2c2c2c',
        secondary: '#504f50',
        hint: rgba('#2c2c2c', 0.46),
      },
      background: {
        default: '#f9f9f9',
      },
    },
    typography: {
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
  })

  const theme = createTheme(
    {
      overrides: {
        MuiButton: {
          root: {
            height: 40,
            minWidth: 160,
            borderRadius: 4,
            fontSize: '0.875rem',
            fontWeight: 500,
            letterSpacing: '0.089286em',
            whiteSpace: 'nowrap',
            [baseTheme.breakpoints.down('sm')]: {
              height: 32,
              minWidth: 112,
              fontSize: '0.75rem',
              letterSpacing: '0.075em',
            },
            [baseTheme.breakpoints.down('xs')]: {
              paddingRight: baseTheme.spacing(1),
              paddingLeft: baseTheme.spacing(1),
            },
          },
          contained: {
            boxShadow: 'none',
          },
          containedSecondary: {
            '&:hover': {
              backgroundColor: baseTheme.palette.secondary.light,
            },
          },
        },
        MuiInput: {
          root: {
            height: 48,
            width: '100%',
            border: 0,
            padding: baseTheme.spacing(0, 1.5),
            backgroundColor: rgba('#e9e9e9', 0.37),
            color: baseTheme.palette.text.primary,
            fontSize: '1rem',
            letterSpacing: '0.03125em',
            [baseTheme.breakpoints.down('sm')]: {
              height: 30,
              padding: baseTheme.spacing(0, 1),
              fontSize: '0.75rem',
              letterSpacing: '0.033333em',
            },
            '& ::placeholder': {
              fontStyle: 'italic',
            },
          },
          multiline: {
            height: 78,
          },
        },
        MuiOutlinedInput: {
          adornedEnd: {
            paddingRight: baseTheme.spacing(1),
          },
        },
        MuiInputLabel: {
          root: {
            display: 'block',
            marginBottom: baseTheme.spacing(1),
            fontSize: '1rem',
            fontWeight: baseTheme.typography.fontWeightBold,
            letterSpacing: '0.03125em',
            lineHeight: 1.2,
            color: baseTheme.palette.text.primary,
            [baseTheme.breakpoints.down('sm')]: {
              marginBottom: baseTheme.spacing(0.5),
              fontSize: '0.75rem',
              letterSpacing: '0.033333em',
            },
          },
        },
      },
      props: {
        MuiButton: {
          variant: 'contained',
          color: 'secondary',
        },
      },
    },
    baseTheme
  )

  return responsiveFontSizes(theme)
}

export const themes = {
  default: makeTheme(),
}
