import React, { createContext, FC, useState } from 'react'

type AudioPlayerContextProps = {
  title: string
  audioUrl: string
  playing: boolean
  enabled: boolean
  play: (title: string, audioUrl: string) => void
  togglePlayPause: () => void
  closePlayer: () => void
}

export const AudioPlayerContext = createContext<AudioPlayerContextProps>({
  title: '',
  audioUrl: '',
  playing: false,
  enabled: false,
  play: () => null,
  togglePlayPause: () => null,
  closePlayer: () => null,
})

export const AudioPlayerContextProvider: FC = ({ children }) => {
  const [title, setTitle] = useState('')
  const [audioUrl, setAudioUrl] = useState('')
  const [playing, setPlaying] = useState(false)
  const [enabled, setEnabled] = useState(false)

  const play = (title: string, audioUrl: string) => {
    setTitle(title)
    setAudioUrl(audioUrl)
    setPlaying(true)
    setEnabled(true)
  }

  const togglePlayPause = () => {
    setPlaying(!playing)
  }

  const closePlayer = () => {
    setPlaying(false)
    setEnabled(false)
  }

  return (
    <AudioPlayerContext.Provider
      value={{
        title,
        audioUrl,
        playing,
        enabled,
        play,
        togglePlayPause,
        closePlayer,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  )
}
