import { makeStyles, MenuList, Paper, Popper } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import clsx from 'clsx'
import { Link } from 'gatsby'
import { rgba } from 'polished'
import React, { FC, FocusEvent, MouseEvent, useState } from 'react'

import { MenuItemAnchor } from '@/components/common/MenuItemAnchor'
import { MenuItemLink } from '@/components/common/MenuItemLink'
import { Route } from '@/routes'

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography, zIndex }) => ({
    link: {
      display: 'flex',
      alignItems: 'center',
      color: palette.primary.dark,
      fontSize: '1.125rem',
      letterSpacing: '0.055556em',
      textDecoration: 'none',
      '&:hover, &:focus': {
        textDecoration: 'underline',
      },
      [breakpoints.down('md')]: {
        fontSize: '1rem',
      },
    },
    focusable: {
      cursor: 'default',
    },
    active: {
      fontWeight: typography.fontWeightBold,
    },
    linkIcon: {
      marginLeft: spacing(0.5),
      fontSize: '1.25rem',
      [breakpoints.down('md')]: {
        fontSize: '0.875rem',
      },
    },
    popper: {
      marginLeft: spacing(-2),
      zIndex: zIndex.tooltip,
    },
    paper: {
      backgroundColor: rgba(palette.background.paper, 0.95),
      borderRadius: 0,
    },
    menuItemLink: {
      color: palette.primary.dark,
      fontSize: '1rem',
      letterSpacing: '0.055625em',
    },
  })
)

type HeaderLinkProps = {
  className?: string
  route: Route
}

export const HeaderLink: FC<HeaderLinkProps> = ({
  className,
  route,
  ...rest
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleMouseover = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleFocus = (event: FocusEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div onMouseLeave={handleClose} className={className}>
      {route.type === 'internal' ? (
        <>
          {route.children ? (
            <div
              tabIndex={0}
              className={clsx(classes.link, classes.focusable)}
              onMouseOver={handleMouseover}
              onFocus={handleFocus}
              {...rest}
            >
              {route.label}
              {route.children && <ExpandMore className={classes.linkIcon} />}
            </div>
          ) : (
            <Link
              className={classes.link}
              activeClassName={classes.active}
              to={route.to}
              {...rest}
            >
              {route.label}
            </Link>
          )}
          {route.children && (
            <Popper
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              placement="bottom-start"
              className={classes.popper}
              disablePortal
            >
              <Paper className={classes.paper}>
                <MenuList>
                  {route.children.map((childRoute) =>
                    childRoute.type === 'internal' ? (
                      <MenuItemLink
                        key={childRoute.label}
                        to={`${route.to}/${childRoute.to}`}
                        className={classes.menuItemLink}
                        activeClassName={classes.active}
                      >
                        {childRoute.label}
                      </MenuItemLink>
                    ) : (
                      <MenuItemAnchor
                        key={childRoute.label}
                        href={childRoute.href}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.menuItemLink}
                      >
                        {childRoute.label}
                      </MenuItemAnchor>
                    )
                  )}
                </MenuList>
              </Paper>
            </Popper>
          )}
        </>
      ) : (
        <a
          className={classes.link}
          href={route.href}
          target="_blank"
          rel="noreferrer"
          {...rest}
        >
          {route.label}
        </a>
      )}
    </div>
  )
}
