import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

import { AudioPlayerContextProvider } from '@/layout/player/AudioPlayerContextProvider'
import { ToastContextProvider } from '@/layout/toast/ToastContextProvider'

import { Footer } from './footer/Footer'
import { Header } from './header/Header'
import { AudioPlayer } from './player/AudioPlayer'

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: palette.background.default,
  },
}))

export const Layout: FC = ({ children }) => {
  const classes = useStyles()

  return (
    <ToastContextProvider>
      <AudioPlayerContextProvider>
        <div className={classes.root}>
          <Header />
          <main>{children}</main>
          <Footer />
          <AudioPlayer />
        </div>
      </AudioPlayerContextProvider>
    </ToastContextProvider>
  )
}
