import { IconButton, makeStyles, Paper, Popper } from '@material-ui/core'
import { Close, Menu } from '@material-ui/icons'
import { rgba } from 'polished'
import React, { FC, MouseEvent, useState } from 'react'

import { routes } from '@/routes'

import { NavMenuLink } from './NavMenuLink'

const useStyles = makeStyles(({ palette, spacing }) => ({
  navIcon: {
    fill: 'currentColor',
  },
  popper: {
    marginLeft: spacing(-2),
  },
  paper: {
    width: '100vw',
    backgroundColor: rgba(palette.background.paper, 0.95),
  },
}))

export const NavMenu: FC = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClickNav = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(open ? null : event.currentTarget)
  }

  const handleClickLink = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton color="primary" size="small" onClick={handleClickNav}>
        {open ? (
          <Close className={classes.navIcon} />
        ) : (
          <Menu className={classes.navIcon} />
        )}
      </IconButton>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom"
        disablePortal
        className={classes.popper}
        modifiers={{
          preventOverflow: {
            padding: 0,
            boundariesElement: 'window',
          },
          offset: {
            enabled: true,
            offset: '-16, 12',
          },
          computeStyle: {
            gpuAcceleration: false,
          },
        }}
      >
        <Paper
          component="nav"
          variant="outlined"
          square
          className={classes.paper}
        >
          {routes.map((route) => (
            <NavMenuLink
              key={route.to}
              route={route}
              onClickLink={handleClickLink}
            />
          ))}
        </Paper>
      </Popper>
    </>
  )
}
