import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { Link } from 'gatsby'
import React, { FC } from 'react'

import { routes } from '@/routes'

const useStyles = makeStyles(({ breakpoints, spacing, typography }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    color: 'inherit',
    [breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
  column: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [breakpoints.down('xs')]: {
      width: '50%',
      marginBottom: spacing(4),
    },
  },
  mainItem: {
    marginBottom: spacing(1),
    fontSize: '1.125rem',
    fontWeight: typography.fontWeightBold,
    lineHeight: 1.056,
    letterSpacing: '0.055555em',
    [breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      lineHeight: 1.583,
    },
  },
  subItem: {
    fontSize: '1rem',
    lineHeight: 1.813,
    letterSpacing: '0.0625em',
    [breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      letterSpacing: '0.041667em',
      lineHeight: 2.1,
    },
  },
  link: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    color: 'inherit',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
}))

export const Sitemap: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {routes.map((route) => (
        <ul className={classes.column} key={route.label}>
          <li className={classes.mainItem}>
            {route.type === 'internal' ? (
              <Link
                to={route.to}
                className={clsx(classes.link, {
                  [classes.disabled]: !!route.children,
                })}
              >
                {route.label}
              </Link>
            ) : (
              <a
                href={route.href}
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                {route.label}
              </a>
            )}
          </li>
          {route.type === 'internal' &&
            route.children &&
            route.children.map((childRoute) => (
              <li key={childRoute.label} className={classes.subItem}>
                {childRoute.type === 'internal' ? (
                  <Link
                    to={`${route.to}/${childRoute.to}`}
                    className={classes.link}
                  >
                    {childRoute.label}
                  </Link>
                ) : (
                  <a
                    href={childRoute.href}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    {childRoute.label}
                  </a>
                )}
              </li>
            ))}
        </ul>
      ))}
    </div>
  )
}
