import { MenuItem, MenuItemProps } from '@material-ui/core'
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby'
import React, { FC } from 'react'

type MenuItemLinkProps = MenuItemProps<
  typeof GatsbyLink,
  GatsbyLinkProps<unknown>
>

export const MenuItemLink: FC<MenuItemLinkProps> = ({ button, ...rest }) => {
  return <MenuItem button component={GatsbyLink} {...rest} />
}
