type InternalRoute = {
  type: 'internal'
  label: string
  to: string
  children?: Route[]
}

type ExternalRoute = {
  type: 'external'
  label: string
  href: string
}

export type Route = InternalRoute | ExternalRoute

export const routes: Route[] = [
  {
    type: 'internal',
    label: 'About Us',
    to: '/about-us',
    children: [
      {
        type: 'internal',
        label: 'Mission and Identity',
        to: 'mission-identity',
      },
      { type: 'internal', label: 'Our Team', to: 'our-team' },
      { type: 'internal', label: 'Faith Statement', to: 'faith-statement' },
    ],
  },
  {
    type: 'internal',
    label: 'Next Steps',
    to: '/next-steps',
    children: [
      { type: 'internal', label: 'Life Groups', to: 'life-groups' },
      { type: 'internal', label: 'Join a Team', to: 'join-team' },
    ],
  },
  { type: 'internal', label: 'Events', to: '/events' },
  {
    type: 'internal',
    label: 'Resources',
    to: '/resources',
    children: [
      {
        type: 'external',
        label: 'Sermons',
        href: 'https://www.youtube.com/@ibcberlin/videos',
      },
      { type: 'internal', label: 'Prayer', to: 'prayer' },
    ],
  },
  { type: 'internal', label: 'Give', to: '/give' },
]
