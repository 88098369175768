import { ListItem, ListItemProps } from '@material-ui/core'
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby'
import React, { FC } from 'react'

type ListItemLinkProps = ListItemProps<
  typeof GatsbyLink,
  GatsbyLinkProps<unknown>
>

export const ListItemLink: FC<ListItemLinkProps> = ({ button, ...rest }) => {
  return <ListItem button component={GatsbyLink} {...rest} />
}
